<template>
  <div class="row">
    <div class="col-sm-12 col-md-6 p-0">
      <client-only>
        <v-calendar
          id="compact-calendar"
          ref="events-calendar"
          color="red"
          :min-date="minimumDate"
          :attributes="calendarOptions"
          @update:pages="calendarPageNavigated"
          @dayclick="calendarDateClicked"
          :locale="getNavigatorLanguage"
        >
          <template v-slot:header-prev-button>
            <div class="calendar-navigation-btn">
              <i class="far fa-long-arrow-left"></i>
            </div>
          </template>
          <template v-slot:header-next-button>
            <div class="calendar-navigation-btn">
              <i class="far fa-long-arrow-right"></i>
            </div>
          </template>
        </v-calendar>
      </client-only>
    </div>
    <div class="col-sm-12 col-md-6 p-0">
      <CompactEventsList
        :events="selectedDailyEvents"
        :maxEvents="MAX_DISPLAY_EVENTS"
        :openInNewWindow="openInNewWindow"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import moment from 'moment';
import CompactEventsList from '@/components/events/CompactEventsList';
import colors from '@/styles/colors.scss?inline';
import { getNavigatorLanguage } from '@/utils/languages';
import { mapState } from 'pinia'
import { useStudioStore } from '@/stores/studio'

export default defineComponent({
  name: 'CompactEventsCalendar',
  components: {
    CompactEventsList,
  },
  props: {
    studioURL: {
      type: String,
    },
    openInNewWindow: {
      type: Boolean,
      default: false,
    },
    isRegistered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: state => state.currentStudio,
      studioEvents: state => state.studioEvents,
      upcomingStudioEvents: state => state.upcomingStudioEvents,
    }),
    calendarOptions() {
      return [...this.calendarOptionsConfig, this.dottedDays];
    },
    selectedDailyEvents() {
      if (this.isLoading) return [];
      const selectedDateEvents = this.upcomingStudioEvents.filter(event =>
        moment(event.event_start_datetime).isSame(this.selectedDate, 'day')
      );
      if (selectedDateEvents.length && this.daySelected) {
        return selectedDateEvents;
      } else {
        return this.upcomingStudioEvents;
      }
    },
    getNavigatorLanguage() {
      return getNavigatorLanguage();
    },
  },
  data() {
    return {
      MAX_DISPLAY_EVENTS: 5,
      selectedCalendarPage: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      selectedDate: new Date(),
      minimumDate: new Date(),
      dottedDays: {},
      calendarOptionsConfig: [
        {
          key: 'today',
          highlight: {
            class: 'todays-date-background',
            contentClass: 'todays-date-content',
          },
          dates: new Date(),
        },
      ],
      daySelected: false,
      isLoading: true,
    };
  },
  methods: {
    calendarPageNavigated(page) {
      if(page[0].id == this.selectedCalendarPage?.id) return;
      this.selectedCalendarPage = page[0];
      this.getStudioEvents();
    },
    calendarDateClicked(day) {
      this.selectedDate = day.date;
      this.daySelected = true;
      this.getUpcomingStudioEvents();
    },
    async getStudioEvents() {
      const y = this.selectedCalendarPage.year,
        m = this.selectedCalendarPage.month - 1;
      const firstDay = moment(new Date(y, m, 1));
      const lastDay = moment(new Date(y, m + 1, 0)).add(1, 'days');
      await useStudioStore().getStudioEventsWithFilter({
        studioURL: this.studioURL,
        fromDate: firstDay,
        toDate: lastDay,
        isRegistered: this.isRegistered,
      });
      if (!this.studioEvents) return;
      const dottedDays = [];
      this.studioEvents.forEach(event => {
        dottedDays.push(moment(event.event_start_datetime).toDate());
        if (moment(event.event_start_datetime).isBefore(this.minimumDate)) {
          this.minimumDate = moment(event.event_start_datetime).toDate();
        }
      });
      this.dottedDays = {
        dot: {
          style: {
            backgroundColor: this.studio?.primary_color || colors.coral,
          },
        },
        dates: dottedDays,
      };
    },
    async getUpcomingStudioEvents() {
      this.isLoading = true;
      await useStudioStore().getUpcomingStudioEvents({
        studioURL: this.studioURL,
        fromDate: this.selectedDate,
        isRegistered: this.isRegistered,
      });
      this.upcomingStudioEvents.forEach(event => {
        if (moment(event.event_start_datetime).isBefore(this.minimumDate)) {
          this.minimumDate = moment(event.event_start_datetime).toDate();
        }
      });
      this.isLoading = false;
    },
  },
  mounted() {
    this.getUpcomingStudioEvents();
  },
});
</script>

<style lang="scss">
@import '@/styles/components-variables.scss';

.vc-light.vc-attr, .vc-light .vc-attr  {
  --vc-highlight-solid-bg: #{$charcoal};
}
</style>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

#compact-calendar {
  --vc-border: transparent;
  --vc-header-title-color: #{$charcoal};
  --vc-weekday-color: #{$dark-gray};
  --vc-day-content-disabled-color: #{$lightest-gray};
  --vc-color: #{$medium-gray};
  --vc-dot-bg: blue;
  font-family: var(--brand-font, 'Poppins');
}

:deep(.vc-day)  {
  padding: 0.75rem 0;
  min-width: 28px;
}

// :deep(.todays-date-background) {
//   background-color: $charcoal;
//   border-radius: 50%;
//   box-sizing: content-box;
// }

// :deep(.todays-date-content)  {
//   color: $white;
// }

// :deep(.vc-day-content)  {
//   padding: 1rem;
// }

// /* move dot a bit lower*/
// :deep(.selectedDailyEvents)  {
//   height: 38px;
// }

// :deep(.vc-dots)  {
//   height: 0;
// }

// /* move buttons away from borders */
// :deep(.vc-arrows-container)  {
//   padding-top: 12px;
//   padding-left: 36px;
//   padding-right: 36px;

//   @media screen and (max-width: 992px) {
//     padding-left: 20px;
//     padding-right: 20px;
//   }
// }

:deep(.vc-arrow)  {
  background: transparent;
  width: 42px;
  &:hover {
    background-color: white;
  }
}

:deep(.vc-light.vc-attr, .vc-light .vc-attr)  {
  --vc-content-color: blue;
}

// :deep(.vc-day-box-center-bottom)  {
//   height: 40px;
// }

:deep(.vc-container)  {
  width: 100%;
  max-width: 100%;
  border: none;
  padding: 10px 15px;
  color: $dark-gray;
}

:deep(.vc-day-content)  {
  &:hover {
    background-color: $dark-gray !important;
    color: $white !important;
  }
  &:focus {
    background-color: var(--brand-color, $coral) !important;
    color: $white !important;
  }
}

:deep(.vc-header)  {
  height: auto;
  margin-bottom: 1.75rem;
  padding-left: 0;
  padding-right: 0;
  @include media-breakpoint-up(sm) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

// :deep(.vc-grid-cell)  {
//   padding: 0.5rem;
// }

:deep(.vc-title)  {
  background: transparent;
  color: $dark-gray !important;
  border-radius: 6px;
  border: 1px solid $medium-gray;
  padding: 5px 10px !important;
  font-family: var(--brand-font, 'Poppins');

  
  &:before {
    color: var(--brand-color, $coral);
    content: '';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    display: inline-block;
    margin-right: 5px;
  }
}

// :deep(.vc-nav-popover-container)  {
//   background-color: $charcoal;
// }

.calendar-navigation-btn {
  margin-top: 8px;
  border-radius: 6px;
  border: 1px solid $medium-gray;
  padding: 6px 10px;
  width: 42px;

  &:hover {
    color: var(--brand-color, $coral);
    border-color: var(--brand-color, $coral);
    box-shadow: 0px 0px 1px 1px var(--brand-color, $coral);
    background-color: $white;
  }
}
</style>

